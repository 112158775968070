import React, { Fragment, useState, useEffect, useRef } from "react";
import type { FC, ChangeEvent } from "react";
import { Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, Divider, FormControl, IconButton, Input, InputLabel,
    ListItemText, MenuItem, Select, Table, TableBody, Tooltip, Typography } from "@mui/material";
import { TableSearch, GridHeader, GridBody, Loader } from "../shared";
import type { UserLicense, UserRights, UserRightsChanged } from "../../types/user";
import { LicenseType, UserType } from "../../types/user";
import Scrollbar from "../Scrollbar";
import { useTranslation } from "react-i18next";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { GridQueryParamsWithSkills } from "../../types/gridQueryParams";
import { SortOrder, ThemeColor, LabelPlacement } from "../../types/enums";
import { ROWSPERPAGE, HTTPSTATUSCODES } from '../../utils/constants';
import { getNrOfTablePages, getSelectAllColumnHeaderWithLabel, getSelectRowElement, getColumnHeader, getEditRowElement, GetColumnHeaderParam, 
    SelectAllColumnHeaderWithLabelParam, GetSelectRowElementParam, GetEditColumnHeaderParam, getEditColumnHeader } from '../../utils/tableElements';
import { useStyles } from "../../theme/styles";
import { userService } from '../../services/users';
import CustomPagination from '../../components/CustomPagination';
import TooltipTextArea from '../../components/TooltipTextArea';
import { Link as RouterLink } from "react-router-dom";
import { MenuRoutes } from "src/types/routes";
import EditIcon from '@mui/icons-material/Edit';
import { SelectListItem } from "src/types/shared";
import InfoIcon from '@mui/icons-material/Info';
import { botAppSelectMenuProps } from "src/utils/botApplicationHelper";
import { skillService } from "src/services/skills";
import { useSnackbar } from "notistack";
import { getSkillsTooltipMessage } from "src/utils/usersHelper";
import { generalSettingsService } from "src/services/generalSettings";
import type { LicenseLimitData, LicenseCalculatedData } from "../../types/license";
import { LicenseInsightView } from "src/components/license";

const UserListTable: FC = (props) => {
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles();
    const [users, setUsers] = useState<UserRights[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [modifiedUsers, setModifiedUsers] = useState<UserRightsChanged[]>([]);
    const [nrOfPages, setNrOfPages] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const rowLimit = useRef<number>(ROWSPERPAGE.DefaultValue);
    const [query, setQuery] = useState<string>("");
    const [order, setOrder] = useState<SortOrder>(SortOrder.Asc);
    const [licenseRefreshNeeded, setLicenseRefreshNeeded] = useState<boolean>(false);
    const [orderBy, setOrderBy] = useState<string>("name");
    const [tableBodyKey, setTableBodyKey] = useState<string>(
        `user-table-body-key-${Math.random()}`
    );
    const [licenseLimitData, setLicenseLimitData] = useState<LicenseLimitData>(null);
    const [licenseCalculatedData, setLicenseCalculatedData] = useState<LicenseCalculatedData>(null);

    const [isActiveUsers, setIsActiveUsers] = useState<string[]>([]);
    const [outboundUsers, setOutboundUsers] = useState<string[]>([]);
    const [isAdminUsers, setIsAdminUsers] = useState<string[]>([]);
    const [isSupervisorUsers, setIsSupervisorUsers] = useState<string[]>([]);
    const [operatorUsers, setOperatorUsers] = useState<string[]>([]);
    const [userLicenses, setUserLicenses] = useState<UserLicense[]>([]);
    const [skills, setSkills] = useState<SelectListItem[]>([]);
    const [selectedSkills, setSelectedSkills] = useState<string[]>([]);

    const isActiveCheckedForAllUsers: boolean = isActiveUsers?.length === users?.length;
    const isOutboundCheckedForAllUsers: boolean = outboundUsers.length === isActiveUsers.length && isActiveUsers.length > 0;
    const isAdminCheckedForAllUsers: boolean = isAdminUsers.length === users?.length;
    const isOperatorCheckedForAllUsers: boolean = operatorUsers.length === isActiveUsers?.length && isActiveUsers.length > 0;

    const [initialGetIsDone, setInitialGetIsDone] = useState<boolean>(false);
    const [getUsersIsDone, setGetUsersIsDone] = useState<boolean>(false);

    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const [isSaveDialogOpen, setSaveDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");

    const licenseTypes = [LicenseType.ProVoice, LicenseType.ProOmni, LicenseType.ProOmniPremium];
    const { t } = typeof jest !== 'undefined' ? { t: s => s } : useTranslation();

    const [areSkillsEnabled, setAreSkillsEnabled] = useState<boolean>();

    const checkIfSkillsAreEnabled = async () => {
        try {
            const response = await generalSettingsService.checkIfSkillsAreEnabled();

            if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
                setAreSkillsEnabled(response.data);
            }
        }
        catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        checkIfSkillsAreEnabled().catch(console.error);
    }, []);

    useEffect(() => {
        if (isMountedRef.current && initialGetIsDone) {
            const delayDebounceFn = setTimeout(() => {
                // Send Axios request here
                const params = getGridUserQueryParamsFromState();

                getPagedUsers(params);
            }, 300);
            return () => clearTimeout(delayDebounceFn);
        }
        return undefined;
    }, [query]);

    useEffect(() => {
        // Send Axios request here
        const params = getGridUserQueryParamsFromState();
        getPagedUsers(params);
    }, []);

    const getPagedUsers = async (queryParams: GridQueryParamsWithSkills) => {
        try {
            const urlParams = urlParamsForFilteredUsers(queryParams);
            const response = await userService.getLicenseDataAndUsers(urlParams);
            if (isMountedRef.current) {
                setUsers(response.data.users.items);
                setInitialState(response.data.users.items);
                initUserLicenses(response.data.users.items);
                setTableBodyKey(`user-table-body-key-${Math.random()}`);

                setNrOfPages(getNrOfTablePages(parseInt(response.data.users.totalCount), rowLimit.current));
                setTotalCount(response.data.users.totalCount);

                if(response.data.licenseLimitData && response.data.licenseCalculatedData){
                    setLicenseLimitData(response.data.licenseLimitData);
                    setLicenseCalculatedData(response.data.licenseCalculatedData);
                }

                setInitialGetIsDone(true);
                setGetUsersIsDone(true);
                setLicenseRefreshNeeded(false);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const initUserLicenses = (userArray: any[]) => {
        const initialUserLicenses = userArray.map(x => {
            return {
                id: x.id,
                initialLicense: x.licenseType
            } as UserLicense;
        });

        setUserLicenses(initialUserLicenses);
    }

    const clearUsersLicenseChanges = (userArray: any[]) => {
        userArray.forEach(user => {
            const userLicense = userLicenses.find(x => x.id === user.id);

            if (userLicense) {
                user.licenseType = userLicense.initialLicense;
            }
        });

        setUserLicenses((prev) => {
            prev.forEach(license => {
                license.newLicense = undefined;
            })

            return prev;
        });
    }

    const setInitialState = (userArray: any[]) => {
        setIsActiveUsers(userArray.filter(user => user?.id && user?.isActive).map(user => user.id));
        setOutboundUsers(userArray.filter(user => user?.id && user?.hasOutboundRights).map(user => user.id));
        setIsAdminUsers(userArray.filter(user => user?.id && user?.isAdmin).map(user => user.id));
        setIsSupervisorUsers(userArray.filter(user => user?.id && user?.isSupervisor).map(user => user.id));
        setOperatorUsers(userArray.filter(user => user?.id && user?.hasOperatorRights).map(user => user.id));
    }

    const getGridUserQueryParamsFromState = () => {
        return {
            limit: rowLimit.current,
            order: order,
            orderBy: orderBy,
            page: page - 1,
            query: query,
            skills: skills.filter(x => selectedSkills.includes(x.name)).map(x => x.id),
            licenseRefreshNeeded: licenseRefreshNeeded
        };
    };

    const urlParamsForFilteredUsers = (params: GridQueryParamsWithSkills) => {
        return `?PageSize=${params.limit}&PageNumber=${params.page}&SortBy=${params.orderBy} ${params.order}&Filter=${params.query}&Skills=${params.skills.join(',')}&LicenseRefreshNeeded=${params.licenseRefreshNeeded}`;
    };

    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setPage(1);
        setQuery(event.target.value);
    };

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === SortOrder.Asc;
        const newOrderValue = isAsc ? SortOrder.Desc : SortOrder.Asc;

        setOrder(newOrderValue);
        setOrderBy(property);
        setPage(1);

        const params = getGridUserQueryParamsFromState();
        params.order = newOrderValue;
        params.orderBy = property;
        params.page = 0;

        getPagedUsers(params);
    };

    const handleSelectAllUsers = (event: ChangeEvent<HTMLInputElement>): void => {
        setSelectedUsers(event.target.checked ? users.map((user) => user.id) : []);
    };

    const handleSelectOneUser = (event: ChangeEvent<HTMLInputElement>, userId: string): void => {
        if (!selectedUsers.includes(userId)) {
            setSelectedUsers((prevSelected) => [...prevSelected, userId]);
        } else {
            setSelectedUsers((prevSelected) => prevSelected.filter((id) => id !== userId));
        }
    };

    const getTouchedUserFromUsers = (userId: string): UserRightsChanged | null => {
        const user = users.find((x) => x.id === userId);

        if (!user) {
            console.error(`unable to find user with userid ${userId}`);
            return null;
        }

        return {
            id: user.id,
            isActive: user.isActive,
            isAdmin: user.isAdmin,
            hasOutboundRights: user.hasOutboundRights,
            hasOperatorRights: user.hasOperatorRights,
            isSupervisor: user.isSupervisor,
            name: user.name,
            userName: user.email,
            licenseType: user.licenseType
        };
    };

    const getTouchedUser = (userId: string): UserRightsChanged | null => {
        let touchedUser = modifiedUsers.find((x) => x.id === userId);

        if (touchedUser === undefined) {
            //first time touching this user , so copy original data then change it
            touchedUser = getTouchedUserFromUsers(userId);
        }

        return touchedUser;
    };

    const handleIsActiveChangedForOneUser = (event: ChangeEvent<HTMLInputElement>, userId: string): void => {
        const isActiveValue = event.target.checked;
        let touchedUser = getTouchedUser(userId);

        if (!touchedUser) {
            return;
        }

        //at this point we have a record on the chaged user, be it newly created or modified
        touchedUser.isActive = isActiveValue;
        setIsActiveUsers(isActiveValue ? [...isActiveUsers, userId] : isActiveUsers.filter(id => id !== userId))

        if (!isActiveValue) {
            setOutboundUsers(prev => prev.filter(id => id !== userId));
            setOperatorUsers(prev => prev.filter(id => id !== userId));
            setIsSupervisorUsers(prev => prev.filter(id => id !== userId))
            touchedUser = clearAttributesAtIsActive(touchedUser);
        }

        handleModifiedUsersChange(userId, touchedUser);
    };

    const handleHasOutBoundRightsChangedForOneUser = (event: ChangeEvent<HTMLInputElement>, userId: string): void => {
        const hasOutboundRights = event.target.checked;
        const touchedUser = getTouchedUser(userId);

        if (!touchedUser) {
            return;
        }

        //at this point we have a record on the chaged user, be it newly created or modified
        touchedUser.hasOutboundRights = hasOutboundRights;
        setOutboundUsers(prev => hasOutboundRights ? [...prev, userId] : prev.filter(id => id !== userId))

        handleModifiedUsersChange(userId, touchedUser);
    };

    const handleIsAdminChangedForOneUser = (
        event: ChangeEvent<HTMLInputElement>,
        userId: string
    ): void => {
        const isAdminValue = event.target.checked;
        const touchedUser = getTouchedUser(userId);

        if (!touchedUser) {
            return;
        }

        //at this point we have a record on the chaged user, be it newly created or modified
        touchedUser.isAdmin = isAdminValue;
        setIsAdminUsers(prev => isAdminValue ? [...prev, userId] : prev.filter(id => id !== userId))

        handleModifiedUsersChange(userId, touchedUser);
    };

    const handleIsSupervisorChangedForOneUser = (
        event: ChangeEvent<HTMLInputElement>,
        userId: string
    ): void => {
        const isSupervisorValue = event.target.checked;
        const touchedUser = getTouchedUser(userId);

        if (!touchedUser) {
            return;
        }

        //at this point we have a record on the chaged user, be it newly created or modified
        touchedUser.isSupervisor = isSupervisorValue;

        if(isSupervisorValue){
            touchedUser.isActive = true;
            setIsActiveUsers( [...isActiveUsers, userId]);
        }
        
        setIsSupervisorUsers(prev => isSupervisorValue ? [...prev, userId] : prev.filter(id => id !== userId));

        handleModifiedUsersChange(userId, touchedUser);
    };

    const handleOperatorChangedForOneUser = (
        event: ChangeEvent<HTMLInputElement>,
        userId: string
    ): void => {
        const operatorValue = event.target.checked;
        const touchedUser = getTouchedUser(userId);

        if (!touchedUser) {
            return;
        }

        touchedUser.hasOperatorRights = operatorValue;

        if(operatorValue){
            touchedUser.isActive = true;
            setIsActiveUsers( [...isActiveUsers, userId]);
        }

        setOperatorUsers(prev => operatorValue ? [...prev, userId] : prev.filter(id => id !== userId));
        handleModifiedUsersChange(userId, touchedUser);
    };

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
        const params = getGridUserQueryParamsFromState();
        params.page = newPage - 1;
        getPagedUsers(params);
        setSelectedUsers([]);
        setGetUsersIsDone(false);
    };

    const onRowsPerPageChangeHandler = (event: any) => {
        rowLimit.current = event.target.value;
        const params = getGridUserQueryParamsFromState();

        if (page * rowLimit.current >= totalCount) {
            params.page = getNrOfTablePages(totalCount, rowLimit.current) - 1;
            setPage(getNrOfTablePages(totalCount, rowLimit.current));
        }

        getPagedUsers(params);
    }

    const saveUsersData = async (): Promise<void> => {
        try {
            setIsSaveInProgress(true);
            const usersToSave = modifiedUsers.map(x => {
                const license = userLicenses.find(ul => x.id === ul.id);

                if (license && license.newLicense !== undefined && license.newLicense !== null) {
                    x.licenseType = license.newLicense;
                }

                return x;
            });

            const response = await userService.updateUsers(usersToSave);
            const isSuccessResponse = response.status == HTTPSTATUSCODES.StatusCodeSuccess;

            if (isSuccessResponse) {
                await refreshUsers(true);
                clearChanges();
                enqueueSnackbar(t("UserList.SnackBar.SaveSuccessfull"), { variant: 'success' });
            }
        } catch (error) {
            enqueueSnackbar(t("UserList.SnackBar.SaveFailed"), { variant: 'error' });
        }
        setIsSaveInProgress(false);
    };

    const handleCancel = (): void => {
        clearChanges();
    };

    const handleSaveButton = (async () => {
        const hasDeactivateUsers = modifiedUsers.some(x => !x.isActive);
        const hasLicenseUpgradedUsers = userLicenses.filter(x => x.newLicense !== undefined).length > 0;

        if (hasDeactivateUsers && hasLicenseUpgradedUsers) {
            setDialogMessage("LicenseInactiveMessage");
            setSaveDialogOpen(true);
            
            return;
        }

        if (hasDeactivateUsers) {
            setDialogMessage("Message");
            setSaveDialogOpen(true);
            
            return;
        }

        if (hasLicenseUpgradedUsers) {
            setDialogMessage("LicenseMessage");
            setSaveDialogOpen(true);

            return;
        }

        await saveUsersData();
    });

    const handleSaveDialogClose = () => {
        setSaveDialogOpen(false);
    }

    const handleSaveDialogConfirmation = (async () => {
        setSaveDialogOpen(false);
        await saveUsersData();
    });

    const clearAttributesAtIsActive = (touchedUser: UserRightsChanged) => {
        touchedUser.hasOutboundRights = false;
        touchedUser.hasOperatorRights = false;
        touchedUser.isSupervisor = false;
        return touchedUser;
    }

    const clearListsAtIsActive = () => {
        setOutboundUsers([]);
        setIsSupervisorUsers([]);
    }

    const handleIsActiveAllUsers = (event: ChangeEvent<HTMLInputElement>): void => {
        setIsActiveUsers(event.target.checked ? users.map((u) => u.id) : []);
        if (!event.target.checked) {
            clearListsAtIsActive();
        }
        const isActiveValue = event.target.checked;
        users.map((u) => u.id).forEach(id => {
            let touchedUser = getTouchedUser(id);

            if (!touchedUser) {
                return;
            }

            touchedUser.isActive = isActiveValue;
            if (!isActiveValue) {
                touchedUser = clearAttributesAtIsActive(touchedUser);
            }

            handleModifiedUsersChange(id, touchedUser);
        });
    };

    const handleOutboundAllUsers = (event: ChangeEvent<HTMLInputElement>): void => {
        if (isActiveUsers.length > 0) {
            const outboundValue = event.target.checked;
            setOutboundUsers(event.target.checked ? isActiveUsers : []);
            users.map((u) => u.id).forEach(id => {
                const touchedUser = getTouchedUser(id);

                if (touchedUser?.isActive) {
                    touchedUser.hasOutboundRights = outboundValue;
                    handleModifiedUsersChange(id, touchedUser);
                }
            });
        }
    };

    const handleIsAdminAllUsers = (event: ChangeEvent<HTMLInputElement>): void => {
        if (users.length > 0) {
            const isAdminValue = event.target.checked;
            setIsAdminUsers(isAdminValue ? users.map((u) => u.id) : []);
            users.map((u) => u.id).forEach(id => {
                const touchedUser = getTouchedUser(id);

                if (!touchedUser) {
                    return;
                }

                touchedUser.isAdmin = isAdminValue;
                if (touchedUser.isAdmin && !touchedUser.hasOperatorRights && !touchedUser.isSupervisor && !touchedUser.isActive) {
                    handleModifiedUsersChange(id, touchedUser);
                }
            });
        }
    };

    const handleIsSupervisorAllUsers = (event: ChangeEvent<HTMLInputElement>): void => {
        if (users.length > 0) {
            const isSupervisorValue = event.target.checked;
            setIsSupervisorUsers(isSupervisorValue ? users.map((u) => u.id) : []);
            users.map((u) => u.id).forEach(id => {
                const touchedUser = getTouchedUser(id);

                if (!touchedUser) {
                    return;
                }

                touchedUser.isSupervisor = isSupervisorValue;
                handleModifiedUsersChange(id, touchedUser);
            });
        }
    };

    const handleOperatorAllUsers = (event: ChangeEvent<HTMLInputElement>): void => {
        if (isActiveUsers.length > 0) {
            const operatorValue = event.target.checked;
            setOperatorUsers(operatorValue ? isActiveUsers : []);
            users.map((u) => u.id).forEach(id => {
                const touchedUser = getTouchedUser(id);

                if (touchedUser?.isActive) {
                    touchedUser.hasOperatorRights = operatorValue;
                    handleModifiedUsersChange(id, touchedUser);
                }
            });
        }
    };

    const refreshUsers = async (licenseRefreshNeededValue: boolean = false): Promise<void> => {
        const params = getGridUserQueryParamsFromState();

        if(licenseRefreshNeededValue){
            params.licenseRefreshNeeded = true;
            setLicenseRefreshNeeded(true);
        }

        await getPagedUsers(params);
    };

    const clearChanges = (): void => {
        setModifiedUsers([]);
        setInitialState(users);
        clearUsersLicenseChanges(users);
    };

    const selectedSomeUsers = selectedUsers && users && selectedUsers.length > 0 && selectedUsers.length < users?.length;
    const selectedAllUsers = selectedUsers && users && selectedUsers.length === users?.length;

    const handleModifiedUsersChange = (userId: string, touchedUser: UserRightsChanged) => {
        setModifiedUsers(prev => isTouchedUserDifferentThanInitial(touchedUser)
            ? [...prev.filter(x => x.id !== userId), touchedUser]
            : prev.filter(x => x.id !== userId));
    }

    const isTouchedUserDifferentThanInitial = (touchedUser: UserRightsChanged) => {
        const initialUser = users.find(x => x.id === touchedUser.id);

        return (touchedUser.isActive !== initialUser.isActive ||
            touchedUser.hasOutboundRights !== initialUser.hasOutboundRights ||
            touchedUser.isAdmin !== initialUser.isAdmin ||
            touchedUser.isSupervisor !== initialUser.isSupervisor ||
            touchedUser.hasOperatorRights !== initialUser.hasOperatorRights) ||
            touchedUser.licenseType !== initialUser.licenseType;
    }

    const getUserType = (type: UserType) => {
        switch (type) {
            case UserType.Database:
                return t("UserList.Database");
            case UserType.AzureActiveDirectory:
                return t("UserList.AAD");
            case UserType.Office365:
                return t("UserList.Office365");
            default:
                return ""
        }
    }

    const getLicenseType = (type: LicenseType) => {
        switch (type) {
            case LicenseType.ProVoice:
                return t("UserList.LicenseType.ProVoice");
            case LicenseType.ProOmni:
                return t("UserList.LicenseType.ProOmni");
            case LicenseType.ProOmniPremium:
                return t("UserList.LicenseType.ProOmniPremium");
            default:
                return "";
        }
    }

    const handleLicenseChange = (event: any, user: UserRights) => {
        const licenseType = event.target.value;
        const touchedUser = getTouchedUser(user.id);

        if (!touchedUser) {
            return;
        }

        setUserLicenses((prevUserLicense) => {
            const touchedUserLicense = prevUserLicense.find((x) => x.id === user.id);

            if (touchedUserLicense) {
                touchedUserLicense.newLicense = licenseType;
            }

            return prevUserLicense;
        });

        user.licenseType = licenseType;
        handleModifiedUsersChange(user.id, touchedUser);
    }

    const getLicenseSelectElement = (user: UserRights) => {
        const touchedUser = getTouchedUser(user.id);
        return (
            <Select
                value={user.licenseType}
                label={t("UserList.License")}
                onChange={(event: any) => handleLicenseChange(event, user)}
                size="small"
                name="licenseType"
                data-testid="select-license-type"
                disabled={touchedUser.isAdmin && !touchedUser.hasOperatorRights && !touchedUser.isSupervisor && !touchedUser.isActive}
                className={classes.usersLicenseType}
            >
                {licenseTypes.map((type) => {
                    return <MenuItem
                        key={getLicenseType(type)}
                        value={type}
                    >
                        {getLicenseType(type)}
                    </MenuItem>
                })}
            </Select>
    )};

    const isActiveAllUsersHeaderKey = "is-active-all-users-header";
    const outboundAllUsersHeaderKey = "outbound-all-users-header";
    const operatorAllUsersHeaderKey = "operator-all-users-header";
    const isAdminAllUsersHeaderKey = "is-admin-all-users-header";
    const isSupervisorAllUsersHeaderKey = "is-supervisor-all-users-header";
    ///end of snackbar
    const headers = [
        {
            key: "select-all-users-header",
            columnName: "",
            columnId: "SelectAllUsers",
            color: ThemeColor.Primary,
            isCheckbox: true,
            isChecked: selectedAllUsers,
            isIndeterminate: selectedSomeUsers,
            changeCallback: handleSelectAllUsers,
            testId: "select-all-users-header",
        },
        getColumnHeader({
            columnName: t("UserList.Name"),
            columnKey: "user-name-header",
            columnId: "name",
            order: order,
            orderBy: orderBy,
            handleRequestSort: handleRequestSort,
            testId: "user-sorton-name"
        } as GetColumnHeaderParam),
        getColumnHeader({
            columnName: t("UserList.Email"),
            columnKey: "user-email-header",
            columnId: "username",
            order: order,
            orderBy: orderBy,
            handleRequestSort: handleRequestSort,
            testId: "user-sorton-email"
        } as GetColumnHeaderParam),
        getColumnHeader({
            columnName: t("UserList.JobTitle"),
            columnKey: "user-job-title-header",
            columnId: "jobTitle",
            order: order,
            orderBy: orderBy,
            handleRequestSort: handleRequestSort,
            testId: "user-sorton-job-title"
        } as GetColumnHeaderParam),
        getColumnHeader({
            columnName: t("UserList.Department"),
            columnKey: "user-department-header",
            columnId: "department",
            order: order,
            orderBy: orderBy,
            handleRequestSort: handleRequestSort,
            testId: "user-sorton-department"
        } as GetColumnHeaderParam),
        getColumnHeader({
            columnName: t("UserList.Type"),
            columnKey: "user-type-header",
            columnId: "type",
            order: order,
            orderBy: orderBy,
            handleRequestSort: handleRequestSort,
            testId: "user-sorton-type"
        } as GetColumnHeaderParam),
        areSkillsEnabled && getColumnHeader({
            columnName: t("UserList.Skills"),
            columnKey: "skills-header",
            columnId: "skills",
            order: order,
            orderBy: orderBy,
            handleRequestSort: handleRequestSort,
            testId: "user-sorton-skills"
        } as GetColumnHeaderParam),
        getColumnHeader({
            columnName: t("UserList.License"),
            columnKey: "user-license-header",
            columnId: "license-type",
            order: order,
            orderBy: orderBy,
            handleRequestSort: handleRequestSort,
            testId: "user-sorton-license-type",
            sx: { padding: "6px", textAlign: "center" }
        } as GetColumnHeaderParam),
        getSelectAllColumnHeaderWithLabel({
            columnName: t("UserList.IsActive"),
            columnKey: isActiveAllUsersHeaderKey,
            columnId: isActiveAllUsersHeaderKey,
            isChecked: isActiveCheckedForAllUsers,
            handleSelectAll: handleIsActiveAllUsers,
            testId: isActiveAllUsersHeaderKey,
            labelPlacement: LabelPlacement.Top,
            style: { padding: "0 0 0 32px", textAlign: "center", whiteSpace: "nowrap" }
        } as SelectAllColumnHeaderWithLabelParam),
        getSelectAllColumnHeaderWithLabel({
            columnName: t("UserList.Outbound"),
            columnKey: outboundAllUsersHeaderKey,
            columnId: outboundAllUsersHeaderKey,
            isChecked: isOutboundCheckedForAllUsers,
            handleSelectAll: handleOutboundAllUsers,
            testId: outboundAllUsersHeaderKey,
            labelPlacement: LabelPlacement.Top,
            style: { padding: "0 0 0 43px", textAlign: "center", whiteSpace: "nowrap" }
        } as SelectAllColumnHeaderWithLabelParam),
        getSelectAllColumnHeaderWithLabel({
            columnName: t("UserList.IsAdmin"),
            columnKey: isAdminAllUsersHeaderKey,
            columnId: isAdminAllUsersHeaderKey,
            isChecked: isAdminCheckedForAllUsers,
            handleSelectAll: handleIsAdminAllUsers,
            testId: isAdminAllUsersHeaderKey,
            labelPlacement: LabelPlacement.Top,
            style: { padding: "0 0 0 32px", textAlign: "center", whiteSpace: "nowrap" }
        } as SelectAllColumnHeaderWithLabelParam),
        getSelectAllColumnHeaderWithLabel({
            columnName: t("UserList.IsSupervisor"),
            columnKey: isSupervisorAllUsersHeaderKey,
            columnId: isSupervisorAllUsersHeaderKey,
            isChecked: isAdminCheckedForAllUsers,
            handleSelectAll: handleIsSupervisorAllUsers,
            testId: isSupervisorAllUsersHeaderKey,
            labelPlacement: LabelPlacement.Top,
            style: { padding: "0 0 0 45px", textAlign: "center", whiteSpace: "nowrap" }
        } as SelectAllColumnHeaderWithLabelParam),
        getSelectAllColumnHeaderWithLabel({
            columnName: t("UserList.Operator"),
            columnKey: operatorAllUsersHeaderKey,
            columnId: operatorAllUsersHeaderKey,
            isChecked: isOperatorCheckedForAllUsers,
            handleSelectAll: handleOperatorAllUsers,
            testId: operatorAllUsersHeaderKey,
            labelPlacement: LabelPlacement.Top,
            style: { padding: "0 0 0 40px", textAlign: "center", whiteSpace: "nowrap" },
            tooltipText: t("UserList.OperatorTooltip")
        } as SelectAllColumnHeaderWithLabelParam),
        areSkillsEnabled && getEditColumnHeader({
            columnName: t('UserList.Edit'),
            columnKey: "users-edit-header",
            columnId: "Edit"
        } as GetEditColumnHeaderParam)
    ];

    const getSkillMenuItem = (skill: SelectListItem) => {
        return (
            <MenuItem 
                key={skill.id}
                value={skill.name}
            >
                <Checkbox checked={Boolean(selectedSkills.find(x => x === skill.name))} />
                <ListItemText primary={skill.name} />
            </MenuItem>
    )};

    const getAllSkills = async (): Promise<void> => {
        try {
            const response = await skillService.getAllSkills();

            if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
                setSkills(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (areSkillsEnabled) {
            getAllSkills();
        }
    }, [areSkillsEnabled]);

    const handleSkillsChange = (event: any) => {
        const params = getGridUserQueryParamsFromState();
        setSelectedSkills(event.target.value);
        setPage(1);
        params.skills = skills.filter(x => event.target.value.includes(x.name)).map(x => x.id);
        params.page = 0;
        getPagedUsers(params);
        setGetUsersIsDone(false);
    };

    return (
        <>
            <Loader isOpen={!initialGetIsDone} isInline={false} />
            {initialGetIsDone &&
            <React.Fragment>
                {licenseLimitData && licenseCalculatedData && <LicenseInsightView licenseLimitData={licenseLimitData} licenseCalculatedData={licenseCalculatedData}/>}
                <Card>
                    <Divider />
                    <Box className={classes.usersGridHeader}>
                        <Box className={classes.queueMembersSearch}>
                            <TableSearch
                                query={query}
                                handleQueryChange={handleQueryChange}
                                placeholder="UserList.SearchUser"
                            />
                            {areSkillsEnabled && (
                                <FormControl className={classes.skillsFilter}>
                                    <InputLabel>{t('UserList.Skills')}</InputLabel >
                                    <Select
                                        labelId="skills-filter-label"
                                        id="skills-filter"
                                        multiple
                                        size="small"
                                        name="skills"
                                        value={selectedSkills}
                                        onChange={handleSkillsChange}
                                        input={<Input />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={botAppSelectMenuProps}
                                        data-testid="skillsFilter"
                                    >
                                        {skills?.map(getSkillMenuItem)}
                                    </Select>
                                </FormControl>
                            )}
                        </Box>
                        <Box className={classes.usersGridButtons}>
                            {modifiedUsers.length > 0 && (
                                <Fragment>
                                    <Button
                                        disabled={isSaveInProgress}
                                        variant="contained"
                                        onClick={handleCancel}
                                        data-testid="btnCancel"
                                        className={classes.cancelButton}
                                    >
                                        {t("Shared.Cancel")}
                                    </Button>
                                    <Button
                                        disabled={isSaveInProgress}
                                        variant="contained"
                                        onClick={handleSaveButton}
                                        className={classes.saveButton}
                                        data-testid="btnSave"
                                    >
                                        {t("Shared.Save")}
                                    </Button>
                                </Fragment>
                            )}
                        </Box>
                    </Box>

                    <Scrollbar>
                        <Box className={classes.usersGrid}>
                            <Loader isOpen={!getUsersIsDone} isInline={true} />
                            {getUsersIsDone &&
                                <Table data-testid="usersGrid">
                                    <GridHeader
                                        key="user-list-grid-header-component"
                                        headers={headers}
                                    />

                                    <TableBody key={tableBodyKey}>
                                        {users.map((user) => {
                                            const isUserSelected = selectedUsers.includes(user.id);
                                            const touchedUser = modifiedUsers.find((x) => x.id === user.id);
                                            const userType = getUserType(user.type);
                                            const rowDetails = {
                                                rowKey: `user-row-${user.id}`,
                                                canHover: true,
                                                rowTestId: `user-row-test`,
                                                isSelected: isUserSelected,
                                            };

                                            const isActiveCheckboxChecked = touchedUser !== undefined
                                                ? touchedUser.isActive
                                                : user.isActive;
                                            const isOutboundCheckboxChecked = touchedUser != undefined
                                                ? touchedUser.isActive && touchedUser.hasOutboundRights
                                                : user.isActive && user.hasOutboundRights;
                                            const isAdminCheckboxChecked = touchedUser != undefined
                                                ? touchedUser.isAdmin
                                                : user.isAdmin;
                                            const isSupervisorCheckboxChecked = touchedUser != undefined
                                                ? touchedUser.isSupervisor
                                                : user.isSupervisor;
                                            const operatorCheckboxChecked = touchedUser != undefined
                                                ? touchedUser.isActive && touchedUser.hasOperatorRights
                                                : user.isActive && user.hasOperatorRights;

                                            const bodyColumns = [
                                                {
                                                    key: `user-item-selectone-${user.id}`,
                                                    id: user.id,
                                                    isCheckbox: true,
                                                    isTextElement: false,
                                                    isChecked: isUserSelected,
                                                    changeCallback: handleSelectOneUser,
                                                    checkBoxValue: isUserSelected,
                                                    color: ThemeColor.Primary,
                                                    sx: { width: "2%" }
                                                },
                                                getEditRowElement(`user-name-${user.name}`, user.name, "13%", <TooltipTextArea text={user.name} />),
                                                getEditRowElement(`user-email-${user.email}`, user.email, "13%", <TooltipTextArea text={user.email} />),
                                                getEditRowElement(`user-jobItile-${user.id}`, user.jobTitle, "13%", <TooltipTextArea text={user.jobTitle} />),
                                                getEditRowElement(`user-department-${user.id}`, user.department, "10%", <TooltipTextArea text={user.department} />),
                                                getEditRowElement(`user-type-${user.id}`, userType, "3%", <TooltipTextArea text={userType} />),
                                                areSkillsEnabled && getEditRowElement(
                                                    `user-skills-${user.id}`, 
                                                    user.id, 
                                                    "4%", 
                                                    <div className={classes.skillColumn}>
                                                        <Typography className={classes.skillCount}>{user.userSkills?.length ?? 0}</Typography>
                                                        {user.userSkills?.length > 0 && (
                                                            <Tooltip title={getSkillsTooltipMessage(user.id, user.userSkills)}>
                                                                <InfoIcon color='primary' />
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                ),
                                                getEditRowElement(`user-license-${user.id}`, user.id, "7%", getLicenseSelectElement(user), `user-test-licenseTypeSelect-${user.id}`),
                                                getSelectRowElement({
                                                    elementKey: `user-item-isActive-${user.id}`,
                                                    elementId: user.id,
                                                    isSelected: isActiveCheckboxChecked,
                                                    handleSelectOne: handleIsActiveChangedForOneUser,
                                                    testId: `user-test-chkActive-${user.id}`,
                                                    isDisabled: false,
                                                    sx: { width: "3%", paddingLeft: "32px", textAlign: "center" }
                                                } as GetSelectRowElementParam),
                                                getSelectRowElement({
                                                    elementKey: `user-item-hasOutbound-${user.id}`,
                                                    elementId: user.id,
                                                    isSelected: isOutboundCheckboxChecked,
                                                    handleSelectOne: handleHasOutBoundRightsChangedForOneUser,
                                                    testId: `user-test-chkHasOutboundRights-${user.id}`,
                                                    isDisabled: !isActiveCheckboxChecked,
                                                    sx: { width: "3%", paddingLeft: "43px", textAlign: "center" }
                                                } as GetSelectRowElementParam),
                                                getSelectRowElement({
                                                    elementKey: `user-item-isAdmin-${user.id}`,
                                                    elementId: user.id,
                                                    isSelected: isAdminCheckboxChecked,
                                                    handleSelectOne: handleIsAdminChangedForOneUser,
                                                    testId: `user-test-chkAdmin-${user.id}`,
                                                    isDisabled: false,
                                                    sx: { width: "3%", paddingLeft: "32px", textAlign: "center" }
                                                } as GetSelectRowElementParam),
                                                getSelectRowElement({
                                                    elementKey: `user-item-isSupervisor-${user.id}`,
                                                    elementId: user.id,
                                                    isSelected: isSupervisorCheckboxChecked,
                                                    handleSelectOne: handleIsSupervisorChangedForOneUser,
                                                    testId: `user-test-chkSupervisor-${user.id}`,
                                                    isDisabled: false,
                                                    sx: { width: "3%", paddingLeft: "45px", textAlign: "center" }
                                                } as GetSelectRowElementParam),
                                                getSelectRowElement({
                                                    elementKey: `user-item-operator-${user.id}`,
                                                    elementId: user.id,
                                                    isSelected: operatorCheckboxChecked,
                                                    handleSelectOne: handleOperatorChangedForOneUser,
                                                    testId: `user-test-chkOperator-${user.id}`,
                                                    isDisabled: false,
                                                    sx: { width: "3%", paddingLeft: "40px", textAlign: "center" }
                                                } as GetSelectRowElementParam),
                                                areSkillsEnabled && getEditRowElement(
                                                    `user-item-edit-${user.id}`, 
                                                    user.id, 
                                                    "3%",
                                                    <IconButton 
                                                        color="primary"
                                                        size="small"
                                                        component={RouterLink}
                                                        to={`${MenuRoutes.Users}/${user.id}`}
                                                        aria-label="edit"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                )
                                            ];

                                            return (
                                                <GridBody
                                                    key={`user-list-grid-body-component${user.id}`}
                                                    elements={bodyColumns}
                                                    rowDetails={rowDetails}
                                                />
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            }
                        </Box>
                    </Scrollbar>
                    <CustomPagination
                        nrOfPages={nrOfPages}
                        page={page}
                        totalCount={totalCount}
                        rowLimit={rowLimit.current}
                        handlePageChange={handlePageChange}
                        onRowsPerPageChange={onRowsPerPageChangeHandler}
                    />
                </Card>
                </React.Fragment>
            }
            <Dialog
                open={isSaveDialogOpen}
                onClose={handleSaveDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t(`UserList.SaveDialog.${dialogMessage}`)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="success"
                        variant="contained"
                        onClick={handleSaveDialogConfirmation}
                    >
                        {t('UserList.SaveDialog.Confirmation')}
                    </Button>
                    <Button
                        autoFocus
                        variant="contained"
                        onClick={handleSaveDialogClose}
                    >
                        {t('UserList.SaveDialog.Decline')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

UserListTable.propTypes = {};

export default UserListTable;
