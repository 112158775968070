import React, { FC } from "react";
import { useStyles } from "src/theme/styles";

interface LicenseInsightItemProps {
	isSingleValue?: boolean;
    singleValue?: number;
    limitValue?: number;
    calculatedValue?: number;
    label?: string;
    hasInsightsLicense?: boolean;
}
const LicenseInsightItem: FC<LicenseInsightItemProps> = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.insightItemContainer}>
            { 
                props.hasInsightsLicense === undefined && <div className={classes.insightValueText}>
                { 
                    props.isSingleValue && props.singleValue ? 
                        <span className={classes.insightSingleText}>{props.singleValue}</span> : 
                        <React.Fragment>
                            <span className={props.calculatedValue > props.limitValue ? classes.calculatedText : ""}>
                                {props.calculatedValue}
                            </span>
                            /
                            <span className={classes.limitText}>
                                {props.limitValue}
                            </span>
                        </React.Fragment>
                }</div>
            }
            {
                props.hasInsightsLicense !== undefined && 
                    <img
                    alt="WrapUpMenuIcon"
                    src={`/static/menu/${props.hasInsightsLicense ? "WrapUpMenuIconEnabled" : "WrapUpMenuIcon"}.svg`}
                    style={{
                        width: "29px",
                        margin: "19px 20px 5px 20px",
                    }}
                    />
            }
            <div className={classes.insightLabel}>{props.label}</div>
        </div>
    );
}

export default LicenseInsightItem;